import React, { useCallback, useContext, useMemo, useState } from 'react';
import numeral from 'numeral';
import {
  Form,
  Button,
  Typography,
  Row,
  Col,
  Descriptions,
  Input,
  Table,
  Checkbox,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import routes from '../../../constants/routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  MUTATION_UPDATE_CORPORATE_CLIENT,
  QUERY_LIST_CORPORATE_CLIENTS_TABLE,
  QUERY_ONE_CORPORATE_CLIENT,
} from '../../../queries/CorporateClientsQueries';
import { ColumnType } from 'antd/lib/table';
import { FIELDS_NAMES } from '../../../components/Tables/const';
import moment from 'moment/moment';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import CorporateClientStatusSelect from '../../../components/Select/CorporateClientStatusSelect';
import { CURRENCY_FORMAT } from '../../../constants/numberformats';
import { PaymentProcessors } from '../../../constants/PaymentProcessors';

const { Title } = Typography;

const AssignmentEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const { data, error, loading } = useQuery(QUERY_ONE_CORPORATE_CLIENT, {
    variables: {
      corporate_client_id: params.corporate_client_id,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateCorporateClient] = useMutation(
    MUTATION_UPDATE_CORPORATE_CLIENT,
    {
      refetchQueries: [{ query: QUERY_LIST_CORPORATE_CLIENTS_TABLE }],
    },
  );

  const handleEditSubmit = useCallback(
    async (values: any) => {
      setSaving(true);
      await updateCorporateClient({
        variables: {
          corporate_client_id: params.corporate_client_id,
          input: { ...values, status_id: Number(values.status_id) },
        },
      });

      setSaving(false);
      setTimeout(() => {
        navigate(routes.CORPORATE_CLIENTS);
      }, 100);
    },
    [navigate, data, updateCorporateClient, params],
  );

  const adminsColumns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: FIELDS_NAMES.NAME,
        dataIndex: 'user',
        key: 'user.full_name',
        render: (user: Record<string, any>) =>
          dbUser?.userPermissions.ManageUsers ? (
            <Link to={routes.USERS + '/' + user.user_id}>{user.full_name}</Link>
          ) : (
            user.full_name
          ),
      },
      {
        title: FIELDS_NAMES.EMAIL,
        dataIndex: ['user', 'email'],
        key: 'user.email',
      },
      {
        title: FIELDS_NAMES.ADDED_ON,
        dataIndex: 'created_on',
        key: 'created_on',
        render: (data: string) =>
          data ? moment(data).format('LLL') : '- not set -',
      },
    ];
  }, []);

  const membershipsColumns: ColumnType<any>[] = useMemo(() => {
    const extraColumns = dbUser?.isCorporateAdmin
      ? []
      : [
          {
            title: FIELDS_NAMES.MAX_CONTAINERS_PER_MONTH,
            dataIndex: 'max_containers_per_month',
            key: 'max_containers_per_month',
          },
          {
            title: FIELDS_NAMES.RENT_DURATION_MAX_DAYS,
            dataIndex: 'rent_duration_max_days',
            key: 'rent_duration_max_days',
          },
          {
            title: FIELDS_NAMES.WARNING_EMAIL_AMOUNT,
            dataIndex: 'warning_email_amount',
            key: 'warning_email_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
          {
            title: FIELDS_NAMES.LOCKED_ACCOUNT_AMOUNT,
            dataIndex: 'locked_account_amount',
            key: 'locked_account_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
        ];

    return [
      {
        title: FIELDS_NAMES.NAME,
        dataIndex: ['membershipLevel', 'name'],
        key: 'membershipLevel.name',
      },
      {
        title: FIELDS_NAMES.ROLE,
        dataIndex: ['clientRole', 'name'],
        key: 'clientRole.name',
      },
      {
        title: FIELDS_NAMES.RENT_DURATION_DAYS,
        dataIndex: 'rent_duration_days',
        key: 'rent_duration_days',
      },
      {
        title: FIELDS_NAMES.MAX_CHECKOUTS_PER_DAY,
        dataIndex: 'max_checkouts_per_day',
        key: 'max_checkouts_per_day',
      },
      {
        title: FIELDS_NAMES.MAX_CONTAINERS_AT_A_TIME,
        dataIndex: 'max_containers_at_a_time',
        key: 'max_containers_at_a_time',
      },
      ...extraColumns,
    ];
  }, [dbUser]);

  if (loading) {
    return <Title level={3}>loading</Title>;
  }

  if (error) {
    return (
      <Title level={3}>
        ERROR: <b>{error.message}</b>
      </Title>
    );
  }

  const corpClient = data.getCorporateClient;
  const link = `${routes.TV_HOSTED_PAGE}/${corpClient.uuid}`;

  if (
    !dbUser?.userPermissions.ManageCorporateClients ||
    (dbUser?.isCorporateAdmin &&
      !dbUser.userCorporateClients.find(
        (c) => c.corporate_client_id === Number(params.corporate_client_id),
      ))
  ) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Descriptions column={1} bordered title={corpClient.name}>
          <Descriptions.Item>
            <Form
              form={form}
              layout="inline"
              name="name"
              onFinish={handleEditSubmit}
              initialValues={{
                name: corpClient.name,
                status_id: corpClient?.status_id?.toString(),
                lock_code: corpClient?.lock_code,
                locked: corpClient?.locked,
              }}
              style={{ marginTop: -10, marginBottom: -10 }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    min: 1,
                    message: 'cannot be empty',
                  },
                ]}
                style={{ marginBottom: 10, width: '300px' }}
              >
                <Input
                  placeholder={corpClient.name}
                  disabled={saving || !dbUser?.userPermissions.DangerZone}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status_id"
                rules={[{ required: true, message: 'Please select status!' }]}
                style={{ marginBottom: 10, width: '200px' }}
              >
                <CorporateClientStatusSelect
                  placeholder="Select status"
                  onChange={(val: any) => form.setFieldValue('status_id', val)}
                  disabled={!dbUser?.userPermissions.DangerZone}
                />
              </Form.Item>
              <Form.Item
                name="locked"
                label="Locked"
                valuePropName="checked"
                style={{ marginBottom: 10, marginLeft: 50, width: '80px' }}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="lock_code"
                label="Lock Code"
                rules={[
                  ({ getFieldValue }) => {
                    return {
                      type: 'string',
                      max: 30,
                      required: !!getFieldValue('locked'),
                      message: 'Please set the code if locked is checked!',
                    };
                  },
                ]}
              >
                <Input
                  placeholder={corpClient.lock_code}
                  disabled={saving || !dbUser?.userPermissions.DangerZone}
                  style={{ marginBottom: 10, width: '100px' }}
                />
              </Form.Item>
              <Button
                loading={saving}
                type="primary"
                htmlType="submit"
                disabled={!dbUser?.userPermissions.DangerZone}
              >
                Update
              </Button>
            </Form>
          </Descriptions.Item>
          {!!corpClient.config_parsed.payment_config
            .default_payment_processor_id && (
            <Descriptions.Item label="Campus Card Provider">
              {
                PaymentProcessors[
                  corpClient.config_parsed.payment_config
                    .default_payment_processor_id
                ]
              }
            </Descriptions.Item>
          )}
          <Descriptions.Item label="TV dashboard URL">
            <span style={{ marginRight: 50 }}>
              {process.env.REACT_APP_URI}
              {link}
            </span>
            <Link to={link + '?admin=true'}>Preview</Link>

            <Link
              to={`${link}/share?admin=true`}
              target="_blank"
              style={{ marginLeft: 50 }}
            >
              Generate Shareable Image
            </Link>
          </Descriptions.Item>
          {corpClient?.clientLevels?.length && (
            <Descriptions.Item label={'Memberships'}>
              <Table
                tableLayout="fixed"
                rowKey={(record) => record.client_level_id}
                dataSource={corpClient.clientLevels}
                columns={membershipsColumns}
                pagination={false}
              />
            </Descriptions.Item>
          )}
          {corpClient?.userCorporateClients?.length && (
            <Descriptions.Item label={'Corporate Client Admins'}>
              <Table
                tableLayout="fixed"
                rowKey={(record) => record.user.user_id}
                dataSource={corpClient.userCorporateClients}
                columns={adminsColumns}
                pagination={false}
              />
            </Descriptions.Item>
          )}
        </Descriptions>
      </Col>
    </Row>
  );
};

export default AssignmentEdit;
